import dynamic from 'next/dynamic';

export const componentMap = {
	componentAccordion: dynamic(() => import('@components/contentful/ComponentAccordion/ComponentAccordion')),
	componentButton: dynamic(() => import('@components/contentful/ContentfulButton/ContentfulButton')),
	componentButtonGrouping: dynamic(() => import('@components/contentful/ButtonGrouping/ButtonGrouping')),
	componentCountdownBanner: dynamic(() => import('@components/contentful/CountdownBanner/CountdownBanner')),
	componentForYouCarousel: dynamic(() => import('@components/account/ForYouCarousel/ForYouCarousel')),
	componentFullWidthTextSection: dynamic(() => import('@components/contentful/FullWidthTextSection/FullWidthTextSection')),
	componentHeroCarousel: dynamic(() => import('@components/contentful/HeroCarousel/HeroCarousel')),
	componentLayout: dynamic(() => import('@components/contentful/ComponentLayout/ComponentLayout')),
	componentLoggedInMarketingGrid: dynamic(() => import('@components/contentful/LoggedInMarketingGrid/LoggedInMarketingGrid')),
	componentLoggedInAccountCard: dynamic(() => import('@components/contentful/LoggedInAccountCard/LoggedInAccountCard')),
	componentLoggedInShopBy: dynamic(() => import('@components/contentful/LoggedInShopBy/LoggedInShopBy')),
	componentMyFavoritesSlider: dynamic(() => import('@components/account/MyFavoritesSlider/MyFavoritesSlider')),
	componentProductList: dynamic(() => import('@components/contentful/ProductList/ProductList')),
	componentStorepoint: dynamic(() => import('@components/contentful/Storepoint/Storepoint')),
	componentTwoPanelContentCard: dynamic(() => import('@components/contentful/TwoPanelContentCard/TwoPanelContentCard')),
	componentValueProposition: dynamic(() => import('@components/contentful/ValueProposition/ValueProposition')),
	componentCardsValueProposition: dynamic(() => import('@components/contentful/CardValueProposition/CardValueProposition')),
	frequentlyAskedQuestions: dynamic(() => import('@components/common/Faqs/Faqs')),
	blockGrid: dynamic(() => import('@components/common/BlockGrid/BlockGrid')),
	hero: dynamic(() => import('@components/contentful/Hero/Hero')),
	howItWorks: dynamic(() => import('@components/contentful/HowItWorks/HowItWorks')),
	imageTitleTextStack: dynamic(() => import('@components/contentful/ImageTitleTextStack/ImageTitleTextStack')),
	locationCard: dynamic(() => import('@components/card/LocationCard/LocationCard')),
	marketingBlocks: dynamic(() => import('@components/contentful/MarketingBlocks/MarketingBlocks')),
	newsletter: dynamic(() => import('@components/contentful/Newsletter/Newsletter')),
	promotionBanner: dynamic(() => import('@components/common/PromotionBanner/PromotionBanner')),
	tabCarousel: dynamic(() => import('@components/common/TabCarousel/TabCarousel')),
	typeform: dynamic(() => import('@components/contentful/Typeform/Typeform')),
};

export const carouselComponentMap = {
	'Collection Tiles': dynamic(() => import('@components/contentful/CollectionCarousel/CollectionCarousel')),
	'Collection Tops Preview': dynamic(() => import('@components/contentful/SneakPeekCarousel/SneakPeekCarousel')),
	'Collection Slider': dynamic(() => import('@components/contentful/FeaturedCollectionSlider/FeaturedCollectionSlider')),
};
